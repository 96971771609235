import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateProjectData,
  CreateProjectProps,
  PinProjectProps,
  ProjectDetail,
  ProjectDetailResponse,
  SelfProjects,
  SelfProjectsByFilter,
  SelfProjectsByFilterResponse,
  SelfProjectsResponse,
  UpdateProjectDetailRequestParameters,
  QueryProjectCheckInStatusProjectProps,
} from './Interface';
import { axiosBaseQuery } from '../axiosBaseQuery';

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSelfProjects: builder.query<SelfProjects[], number>({
      query: (groupId) => ({
        url: `/api/project/GetSelfProjects`,
        method: 'GET',
        params: { GroupId: groupId },
      }),
      transformResponse: (response: SelfProjectsResponse) => response.data.data,
      forceRefetch: () => true, // 實驗更新狀況
    }),
    QueryProjectByFilter: builder.query<SelfProjectsByFilter[], number>({
      query: (projectId) => ({
        url: `/api/Project/QueryProjectByFilter`,
        method: 'GET',
        params: { id: projectId },
      }),
      transformResponse: (response: SelfProjectsByFilterResponse) =>
        response.data.data,
    }),
    getProjectDetail: builder.query<ProjectDetail, number>({
      query: (projectId) => ({
        url: `/api/Project/GetProjectDetail`,
        method: 'GET',
        params: { projectId: projectId },
      }),
      transformResponse: (response: ProjectDetailResponse) =>
        response.data.data,
    }),
    CreateProject: builder.mutation<CreateProjectData, CreateProjectProps>({
      query: (data) => ({
        url: `/api/Project/CreateProject`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    UpdateProjectDetail: builder.mutation<
      UpdateProjectDetailRequestParameters,
      any
    >({
      query: (data) => ({
        url: `/api/Project/UpdateProjectDetail`,
        method: 'PUT',
        data: data,
      }),
      transformResponse: (response: ProjectDetailResponse) =>
        response.data.data,
    }),
    PinProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/PinProject`,
        method: 'PUT',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    UnPinProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/UnPinProject`,
        method: 'PUT',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    LeaveProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/Leave`,
        method: 'POST',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    CheckProjectAttendanceToday: builder.query<any, number>({
      query: (projectId) => ({
        url: `/api/project/CheckProjectAttendanceToday`,
        method: 'GET',
        params: { projectId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    ArchiveProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/ArchiveProject`,
        method: 'PUT',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    DeleteProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/DeleteProject`,
        method: 'DELETE',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    AddUserToProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId, userIds = [] }) => ({
        url: `/api/Project/AddUserToProject`,
        method: 'POST',
        data: { projectId, userId, roleId, userIds },
      }),
      transformResponse: (response) => response.data.data,
    }),
    ModifyUserRole: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/ModifyUserRole`,
        method: 'PUT',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    RemoveUser: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId, userIds = [] }) => ({
        url: `/api/Project/RemoveUser`,
        method: 'POST',
        data: { projectId, userId, roleId, userIds },
      }),
      transformResponse: (response) => response.data.data,
    }),
    GetProjectCurrentStatusSummary: builder.query<any, number>({
      query: (projectId) => ({
        url: `/api/Project/GetProjectCurrentStatusSummary`,
        method: 'GET',
        params: { ProjectId: projectId },
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: () => true,
    }),
    GetActivityViewModelsByProjectWithPaged: builder.query<any, any>({
      query: ({ projectId, pageCount, pageNumber, createdBy }) => ({
        url: `/api/Project/GetActivityViewModelsByProjectWithPaged`,
        method: 'GET',
        params: { projectId, pageCount, pageNumber, createdBy },
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: () => true,
    }),
    QueryProjectCheckInStatus: builder.mutation<
      any,
      QueryProjectCheckInStatusProjectProps
    >({
      query: ({ projectId, projectIds, userId, roleId, userIds }) => ({
        url: `/api/Project/QueryProjectCheckInStatus`,
        method: 'POST',
        data: { projectId, projectIds, userId, roleId, userIds },
      }),
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const {
  useGetSelfProjectsQuery,
  useQueryProjectByFilterQuery,
  useGetProjectDetailQuery,
  useCreateProjectMutation,
  useUpdateProjectDetailMutation,
  usePinProjectMutation,
  useUnPinProjectMutation,
  useLeaveProjectMutation,
  useCheckProjectAttendanceTodayQuery,
  useArchiveProjectMutation,
  useDeleteProjectMutation,
  useAddUserToProjectMutation,
  useModifyUserRoleMutation,
  useRemoveUserMutation,
  // track
  useGetProjectCurrentStatusSummaryQuery,
  useQueryProjectCheckInStatusMutation,
  useGetActivityViewModelsByProjectWithPagedQuery,
} = projectsApi;
