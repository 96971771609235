import request from '@/utils/request';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import {
  Equipment,
  EquipmentAttendanceRecordByPageFilterRequest,
  EquipmentAttendanceRecordByPageFilterResponse,
  EquipmentAttendanceRecordDetail,
  EquipmentDetailResponse,
  EquipmentHistory,
  EquipmentHistoryListDetailByPageFilterRequest,
  EquipmentHistoryListDetailByPageFilterResponse,
  EquipmentHourlyReport,
  EquipmentLocationDetail,
  EquipmentLocationRecord,
  EquipmentLocationRecordData,
  EquipmentProductivityData,
  EquipmentProductivityResponse,
  EquipmentResponse,
  QueryCurrentEquipmentsByFilterReturnGroupedData,
  QueryCurrentEquipmentsByFilterReturnGroupedResponse,
  QueryEquipmentByFilterRequest,
  QueryEquipmentLocationRecordByPageFilterRequest,
  QueryEquipmentRequest,
  UpdateEquipmentAttendanceRecordRequest,
  UpdateEquipmentStatusRequest,
} from './interface';
import BaseToast from '@/components/base/BaseToast/BaseToast';
import { AxiosResponse } from 'axios';

const URL = '/api/Equipment';

export function QueryCheckInEquipments(data) {
  return request({
    url: `/api/Equipment/QueryCheckInEquipments`,
    method: 'POST',
    data: data,
  });
}

interface QueryCheckInEquipmentsPayload {
  projectId: number;
  date: string;
}

export const equipmentApi = createApi({
  reducerPath: 'equipmentApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Equipment', 'EquipmentDetail', 'EquipmentRecord'],
  endpoints: (builder) => ({
    QueryCheckInEquipments: builder.mutation<
      any,
      QueryCheckInEquipmentsPayload
    >({
      query: (data) => ({
        url: '/api/Equipment/QueryCheckInEquipments',
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
      // invalidatesTags: ['Equipment'],
    }),
    queryEquipmentsByPageFilter: builder.query<
      EquipmentResponse,
      QueryEquipmentRequest
    >({
      query: (data) => ({
        url: '/api/Equipment/QueryEquipmentsByPageFilter',
        method: 'POST',
        data,
      }),
      providesTags: ['Equipment'],
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.GroupId}_${queryArgs.PageNumber}`;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.PageNumber !== previousArg?.PageNumber ||
          currentArg?.Keyword !== previousArg?.Keyword ||
          currentArg?.GroupId !== previousArg?.GroupId
        );
      },
      transformResponse: (response) => response.data.data,
    }),
    queryEquipmentsByFilter: builder.query<
      Equipment[],
      QueryEquipmentByFilterRequest
    >({
      query: (data) => ({
        url: '/api/Equipment/QueryEquipmentsByFilter',
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      transformResponse: (response) => response.data.data,
    }),
    queryEquipmentLocationRecordByPageFilter: builder.query<
      EquipmentLocationRecord,
      QueryEquipmentLocationRecordByPageFilterRequest
    >({
      query: (params) => ({
        url: '/api/Equipment/QueryEquipmentLocationRecordByPageFilter',
        method: 'GET',
        params,
      }),
      providesTags: ['EquipmentRecord'],
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.PageNumber !== previousArg?.PageNumber;
      },
      // merge: (currentCacheData, responseData) => {
      //   const newData = responseData.recordPageData.data.filter(
      //     (newItem) =>
      //       !currentCacheData.recordPageData.data.some(
      //         (item) =>
      //           item.equipmentAttendanceRecordId ===
      //           newItem.equipmentAttendanceRecordId,
      //       ),
      //   );

      //   currentCacheData.recordPageData.data = [
      //     ...currentCacheData.recordPageData.data,

      //     ...newData,
      //   ].sort(
      //     (a, b) =>
      //       (b.equipmentAttendanceRecordId ?? 0) -
      //       (a.equipmentAttendanceRecordId ?? 0),
      //   );
      //   currentCacheData.recordPageData.currentPageCount =
      //     responseData.recordPageData.currentPageCount;

      //   currentCacheData.recordPageData.pageCount =
      //     responseData.recordPageData.pageCount;

      //   currentCacheData.recordPageData.totalCount =
      //     responseData.recordPageData.totalCount;

      //   currentCacheData.recordPageData.pageNumber =
      //     responseData.recordPageData.pageNumber;

      //   currentCacheData.recordPageData.totalPageCount =
      //     responseData.recordPageData.totalPageCount;

      //   currentCacheData.users = responseData.users;
      // },
      transformResponse: (response) => response.data.data,
    }),
    queryEquipmentAttendanceRecordByPageFilter: builder.query<
      EquipmentAttendanceRecordByPageFilterResponse,
      EquipmentAttendanceRecordByPageFilterRequest
    >({
      query: (data) => ({
        url: '/api/Equipment/QueryEquipmentAttendanceRecordByPageFilter',
        method: 'GET',
        params: data,
      }),
      providesTags: ['EquipmentRecord'],
      transformResponse: (response) => response.data.data,
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.PageNumber !== previousArg?.PageNumber ||
          currentArg?.EquipmentId !== previousArg?.EquipmentId ||
          currentArg?.StartDate !== previousArg?.StartDate ||
          currentArg?.EndDate !== previousArg?.EndDate
        );
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        // 使用 equipmentId 作為緩存 key 的一部分
        return `${endpointName}-${queryArgs.EquipmentId}`;
      },
      merge: (currentCache, newItems, { arg }) => {
        // 如果是第一頁，直接更新資料
        let mergedData;
        if (arg.PageNumber === 1) {
          mergedData = newItems;
        } else {
          mergedData = {
            ...newItems,
            recordPageData: {
              ...newItems.recordPageData,
              data: [
                ...(currentCache.recordPageData?.data || []),
                ...(newItems.recordPageData?.data || []),
              ],
            },
            users: Array.from(
              new Set(
                [...(currentCache.users || []), ...(newItems.users || [])].map(
                  (user) => user.userId,
                ),
              ),
            ).map((userId) =>
              [...(currentCache.users || []), ...(newItems.users || [])].find(
                (user) => user.userId === userId,
              ),
            ),
          };
        }

        console.log('mergedData:', mergedData);
        return mergedData;
      },
    }),
    queryEquipmentAttendanceRecordByTimeFilter: builder.query<
      EquipmentAttendanceRecordByPageFilterResponse,
      EquipmentAttendanceRecordByPageFilterRequest
    >({
      query: (data) => ({
        url: '/api/Equipment/QueryEquipmentAttendanceRecordByPageFilter',
        method: 'GET',
        params: data,
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.PageNumber !== previousArg?.PageNumber ||
          currentArg?.EquipmentId !== previousArg?.EquipmentId ||
          currentArg?.StartDate !== previousArg?.StartDate ||
          currentArg?.EndDate !== previousArg?.EndDate
        );
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        // 使用 equipmentId 作為緩存 key 的一部分
        return `${endpointName}-${queryArgs.EquipmentId}-${queryArgs.StartDate}-${queryArgs.EndDate}`;
      },
      merge: (currentCache, newItems, { arg }) => {
        // 如果是第一頁，直接更新資料
        let mergedData;
        if (arg.PageNumber === 1) {
          mergedData = newItems;
        } else {
          mergedData = {
            ...newItems,
            recordPageData: {
              ...newItems.recordPageData,
              data: [
                ...(currentCache.recordPageData?.data || []),
                ...(newItems.recordPageData?.data || []),
              ],
            },
            users: Array.from(
              new Set(
                [...(currentCache.users || []), ...(newItems.users || [])].map(
                  (user) => user.userId,
                ),
              ),
            ).map((userId) =>
              [...(currentCache.users || []), ...(newItems.users || [])].find(
                (user) => user.userId === userId,
              ),
            ),
          };
        }

        console.log('mergedData:', mergedData);
        return mergedData;
      },
    }),
    getEquipmentHistoryListDetailByPageFilter: builder.query<
      EquipmentHistoryListDetailByPageFilterResponse,
      EquipmentHistoryListDetailByPageFilterRequest
    >({
      query: (data) => ({
        url: '/api/Equipment/GetEquipmentHistoryListDetailByPageFilter',
        method: 'GET',
        params: data,
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.PageNumber !== previousArg?.PageNumber ||
          currentArg?.TemplateIds !== previousArg?.TemplateIds ||
          currentArg?.EquipmentId !== previousArg?.EquipmentId
        );
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.EquipmentId}-${queryArgs.TemplateIds}`;
      },
      merge: (currentCache, newItems, { arg }) => {
        // 如果是第一頁，直接更新資料
        let mergedData;
        if (arg.PageNumber === 1) {
          mergedData = newItems;
        } else {
          mergedData = {
            ...newItems,
            data: [...(currentCache.data || []), ...(newItems.data || [])],
          };
        }
        return mergedData;
      },
    }),
    getEquipmentDetail: builder.query<EquipmentDetailResponse, number>({
      query: (equipmentId) => ({
        url: '/api/Equipment/GetEquipmentDetail',
        method: 'GET',
        params: { equipmentId },
      }),
      providesTags: ['EquipmentDetail'],
      transformResponse: (response) => response.data.data,
    }),
    getEquipmentAttendanceRecords: builder.query<any, number>({
      query: (equipmentId) => ({
        url: '/api/Equipment/GetEquipmentAttendanceRecords',
        method: 'GET',
        params: { equipmentId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    getEquipmentHistories: builder.query<EquipmentHistory[], number>({
      query: (equipmentId) => ({
        url: '/api/Equipment/GetEquipmentHistories',
        method: 'GET',
        params: { equipmentId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    getEquipmentHourlyReport: builder.query<
      EquipmentHourlyReport[],
      { equipmentId: number; year: number }
    >({
      query: ({ equipmentId, year }) => ({
        url: '/api/Equipment/GetEquipmentHourlyReport',
        method: 'GET',
        params: { equipmentId, year },
      }),
      transformResponse: (response) => response.data.data,
    }),
    getEquipmentAttendanceRecordDetail: builder.query<
      EquipmentAttendanceRecordDetail,
      number
    >({
      query: (equipmentAttendanceRecordId) => ({
        url: '/api/Equipment/GetEquipmentAttendanceRecordDetail',
        method: 'GET',
        params: { equipmentAttendanceRecordId },
      }),
      providesTags: ['EquipmentRecord'],
      transformResponse: (response) => response.data.data,
    }),
    getEquipmentWithLocationDetail: builder.query<
      EquipmentLocationDetail,
      number
    >({
      query: (equipmentId) => ({
        url: '/api/Equipment/GetEquipmentWithLocationDetail',
        method: 'GET',
        params: { equipmentId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    createEquipment: builder.mutation<any, FormData>({
      query: (data) => ({
        url: '/api/Equipment/CreateEquipment',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Equipment'],
    }),
    updateEquipment: builder.mutation<any, FormData>({
      query: (data) => ({
        url: '/api/Equipment/UpdateEquipment',
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Equipment', 'EquipmentDetail'],
    }),
    duplicateEquipment: builder.mutation<any, number>({
      query: (equipmentId) => ({
        url: '/api/Equipment/DuplicateEquipment',
        method: 'POST',
        data: { EquipmentId: equipmentId },
      }),
      invalidatesTags: ['Equipment'],
    }),
    deleteEquipment: builder.mutation<any, number>({
      query: (equipmentId) => ({
        url: '/api/Equipment/DeleteEquipment',
        method: 'DELETE',
        data: { EquipmentId: equipmentId },
      }),
      invalidatesTags: ['Equipment'],
    }),
    updateEquipmentStatus: builder.mutation<any, UpdateEquipmentStatusRequest>({
      query: (data) => ({
        url: '/api/Equipment/UpdateEquipmentStatus',
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Equipment', 'EquipmentDetail'],
    }),
    updateEquipmentAttendanceRecord: builder.mutation<
      any,
      UpdateEquipmentAttendanceRecordRequest
    >({
      query: (data) => ({
        url: '/api/Equipment/UpdateEquipmentAttendanceRecord',
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['EquipmentRecord'],
    }),
    deleteEquipmentAttendanceRecord: builder.mutation<any, number>({
      query: (equipmentAttendanceRecordId) => ({
        url: '/api/Equipment/DeleteEquipmentAttendanceRecord',
        method: 'DELETE',
        data: { EquipmentAttendanceRecordId: equipmentAttendanceRecordId },
      }),
      invalidatesTags: ['EquipmentRecord'],
    }),
    getEquipmentProductivity: builder.query<EquipmentProductivityData, any>({
      query: (data) => ({
        url: '/api/Equipment/GetEquipmentProductivity',
        method: 'GET',
        params: data,
      }),
      providesTags: ['EquipmentRecord'],
      transformResponse: (
        response: AxiosResponse<EquipmentProductivityResponse>,
      ) => response.data.data,
    }),
    queryCurrentEquipmentsByFilterReturnGrouped: builder.query<
      QueryCurrentEquipmentsByFilterReturnGroupedData[],
      any
    >({
      query: (data) => ({
        url: '/api/Equipment/queryCurrentEquipmentsByFilterReturnGrouped',
        method: 'POST',
        data,
      }),
      providesTags: ['EquipmentRecord'],
      transformResponse: (
        response: AxiosResponse<QueryCurrentEquipmentsByFilterReturnGroupedResponse>,
      ) => response.data.data,
    }),
    // track
    QueryEquipmentsWithLocation: builder.query<any, any>({
      query: ({ keyword, groupId, createdBy }) => ({
        url: '/api/Equipment/QueryEquipmentsWithLocation',
        method: 'GET',
        params: {
          keyword: keyword,
          groupId: groupId,
          createdBy: createdBy,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const {
  useQueryCheckInEquipmentsMutation,
  useQueryEquipmentsByPageFilterQuery,
  useQueryEquipmentLocationRecordByPageFilterQuery,
  useQueryEquipmentAttendanceRecordByPageFilterQuery,
  useGetEquipmentAttendanceRecordsQuery,
  useGetEquipmentDetailQuery,
  useGetEquipmentHistoriesQuery,
  useGetEquipmentHourlyReportQuery,
  useQueryEquipmentsByFilterQuery,
  useGetEquipmentAttendanceRecordDetailQuery,
  useGetEquipmentWithLocationDetailQuery,
  useCreateEquipmentMutation,
  useUpdateEquipmentMutation,
  useDuplicateEquipmentMutation,
  useDeleteEquipmentMutation,
  useUpdateEquipmentStatusMutation,
  useUpdateEquipmentAttendanceRecordMutation,
  useDeleteEquipmentAttendanceRecordMutation,
  useQueryEquipmentAttendanceRecordByTimeFilterQuery,
  useGetEquipmentHistoryListDetailByPageFilterQuery,
  useGetEquipmentProductivityQuery,
  useQueryCurrentEquipmentsByFilterReturnGroupedQuery,
  // track
  useQueryEquipmentsWithLocationQuery,
} = equipmentApi;
